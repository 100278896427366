import { getPlots } from "./property-helpers";

export const AREA_TYPE_RESIDENTIAL = "residential";
export const AREA_TYPE_RESIDENTIAL_WITHOUT_ATTIC = "residential_without_attic";
export const AREA_TYPE_RESIDENTIAL_ATTIC = "residential_attic";
export const AREA_TYPE_BASEMENT = "basement";
export const AREA_TYPE_ANNEX = "annex";
export const AREA_TYPE_BUILTIN_GARAGE = "builtin_garage";
export const AREA_TYPE_BUILTIN_OUTHOUSE = "builtin_outhouse";
export const AREA_TYPE_GARAGE_FOR_ONE_OR_TWO_CARS = "garage_for_one_or_two_cars";
export const AREA_TYPE_OUTHOUSE = "outhouse";
export const AREA_TYPE_BUILTIN_CONSERVATORY = "builtin_conservatory";
export const AREA_TYPE_DETACHED_CONSERVATORY = "detached_conservatory";
export const AREA_TYPE_GROWTH_HOUSE = "growth_house";
export const AREA_TYPE_CARPORT = "carport";
export const AREA_TYPE_BUILTIN_CARPORT = "builtin_carport";
export const AREA_TYPE_UNUSED_ATTIC = "unused_attic";
export const AREA_TYPE_LEFTOVER_AGRICULTURE = "leftover_agriculture";

// Default
export const areaWeights = {
  [AREA_TYPE_RESIDENTIAL_WITHOUT_ATTIC]: 1.0,
  [AREA_TYPE_RESIDENTIAL_ATTIC]: 0.9,
  [AREA_TYPE_ANNEX]: 0.8,
  [AREA_TYPE_BASEMENT]: 0.4,
  [AREA_TYPE_BUILTIN_GARAGE]: 0.4,
  [AREA_TYPE_BUILTIN_OUTHOUSE]: 0.4,
  [AREA_TYPE_GARAGE_FOR_ONE_OR_TWO_CARS]: 0.25,
  [AREA_TYPE_OUTHOUSE]: 0.25,
  [AREA_TYPE_BUILTIN_CONSERVATORY]: 0.25,
  [AREA_TYPE_DETACHED_CONSERVATORY]: 0.15,
  [AREA_TYPE_GROWTH_HOUSE]: 0.1,
  [AREA_TYPE_BUILTIN_CARPORT]: 0.1,
  [AREA_TYPE_CARPORT]: 0.1,
  [AREA_TYPE_UNUSED_ATTIC]: 0.1,
  [AREA_TYPE_LEFTOVER_AGRICULTURE]: 0.1,
};

/**
 * Calculates the weighted area of the property based on a map of weights on the form AREA_TYPE_NAME => WEIGHT
 */
export const calculateWeightedArea = function (property, weights) {
  if (property.condo != null) {
    return property.condo.unit?.residentialArea || 0;
  }

  let totalArea = 0;

  Object.entries(weights).forEach((entry) => {
    const areaType = entry[0];
    const weight = entry[1];

    switch (areaType) {
      case AREA_TYPE_RESIDENTIAL:
        totalArea += calculateResindentialArea(property) * weight;
        break;
      case AREA_TYPE_RESIDENTIAL_WITHOUT_ATTIC:
        totalArea += calculateResidentialAreaWithoutAtticArea(property) * weight;
        break;
      case AREA_TYPE_RESIDENTIAL_ATTIC:
        totalArea += calculateResidentialAtticArea(property) * weight;
        break;
      case AREA_TYPE_BASEMENT:
        totalArea += calculateBasementArea(property) * weight;
        break;
      case AREA_TYPE_ANNEX:
        totalArea += calculateAnnexArea(property) * weight;
        break;
      case AREA_TYPE_BUILTIN_GARAGE:
        totalArea += calculateBuiltinGarageArea(property) * weight;
        break;
      case AREA_TYPE_BUILTIN_OUTHOUSE:
        totalArea += calculateBuiltinOuthouseArea(property) * weight;
        break;
      case AREA_TYPE_GARAGE_FOR_ONE_OR_TWO_CARS:
        totalArea += calculateGarageForOneOrTwoCarsArea(property) * weight;
        break;
      case AREA_TYPE_OUTHOUSE:
        totalArea += calculateOuthouseArea(property) * weight;
        break;
      case AREA_TYPE_BUILTIN_CONSERVATORY:
        totalArea += calculateBuiltinConservatoryArea(property) * weight;
        break;
      case AREA_TYPE_DETACHED_CONSERVATORY:
        totalArea += calculateDetachedConservatoryArea(property) * weight;
        break;
      case AREA_TYPE_GROWTH_HOUSE:
        totalArea += calculateGrowthHouseArea(property) * weight;
        break;
      case AREA_TYPE_CARPORT:
        totalArea += calculateCarportArea(property) * weight;
        break;
      case AREA_TYPE_BUILTIN_CARPORT:
        totalArea += calculateBuiltinCarportArea(property) * weight;
        break;
      case AREA_TYPE_UNUSED_ATTIC:
        totalArea += calculateUnusedAtticArea(property) * weight;
        break;
      case AREA_TYPE_LEFTOVER_AGRICULTURE:
        totalArea += calculateLeftoverAgricultureArea(property) * weight;
        break;
      default:
        console.warn("Unknown area type: " + areaType);
        break;
    }
  });

  return totalArea;
};

export const calculateResindentialArea = function (property) {
  return getBuildings(property)
    .filter((building) => building.totalUsableArea != null)
    .reduce((a, b) => {
      return a + b.totalUsableArea;
    }, 0);
};

export const calculateResidentialAreaWithoutAtticArea = function (property) {
  const totalUsableArea = getBuildings(property)
    .filter((building) => building.totalUsableArea != null)
    .reduce((a, b) => {
      return a + b.totalUsableArea;
    }, 0);

  const totalAtticArea = calculateResidentialAtticArea(property);

  return totalUsableArea - totalAtticArea;
};

export const calculateResidentialAtticArea = function (property) {
  return getFloors(property)
    .filter((floor) => floor.utilizedAtticArea != null)
    .reduce((a, b) => {
      return a + b.utilizedAtticArea;
    }, 0);
};

export const calculateBasementArea = function (property) {
  return getFloors(property)
    .filter((floor) => floor.basementArea != null)
    .reduce((a, b) => {
      return a + b.basementArea;
    }, 0);
};

export const calculateBuiltinGarageArea = function (property) {
  return getBuildings(property)
    .filter((building) => building.garageArea != null)
    .reduce((a, b) => {
      return a + b.garageArea;
    }, 0);
};

export const calculateBuiltinOuthouseArea = function (property) {
  return getBuildings(property)
    .filter((building) => building.builtinOuthouseArea != null)
    .reduce((a, b) => {
      return a + b.builtinOuthouseArea;
    }, 0);
};

export const calculateAnnexArea = function (property) {
  const ANNEX_USAGE_TYPE_1 = 185;
  const ANNEX_USAGE_TYPE_2 = 585;

  return getBuildings(property)
    .filter((b) => {
      return (b.usageCode == ANNEX_USAGE_TYPE_1 || b.usageCode == ANNEX_USAGE_TYPE_2) && b.totalBuildingArea != null;
    })
    .reduce((a, b) => a + b.totalBuildingArea, 0);
};

export const calculateGarageForOneOrTwoCarsArea = function (property) {
  const GARAGE_FOR_ONE_OR_TWO_CARS_USAGE_TYPE = 910;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == GARAGE_FOR_ONE_OR_TWO_CARS_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea || 0, 0);
};

export const calculateOuthouseArea = function (property) {
  const OUTHOUSE_USAGE_TYPE = 930;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == OUTHOUSE_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea || 0, 0);
};

export const calculateBuiltinConservatoryArea = function (property) {
  return getBuildings(property)
    .filter((b) => {
      return b.outhouseArea != null;
    })
    .reduce((a, b) => {
      return a + b.outhouseArea;
    }, 0);
};

export const calculateDetachedConservatoryArea = function (property) {
  const DETACHED_CONSERVATORY_USAGE_TYPE = 960;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == DETACHED_CONSERVATORY_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea, 0);
};

export const calculateGrowthHouseArea = function (property) {
  const GROWTH_HOUSE_USAGE_TYPE = 940;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == GROWTH_HOUSE_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea, 0);
};

export const calculateCarportArea = function (property) {
  const CARPORT_USAGE_TYPE = 920;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == CARPORT_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea, 0);
};

export const calculateBuiltinCarportArea = function (property) {
  return getBuildings(property)
    .filter((b) => {
      return b.carportArea != null;
    })
    .reduce((a, b) => {
      return a + b.carportArea;
    }, 0);
};

export const calculateUnusedAtticArea = function (property) {
  const floors = getFloors(property);
  const atticArea = floors.filter((f) => f.floorTypeCode == 1 && f.floorArea != null).reduce((a, f) => a + f.floorArea, 0);
  const utilizedAtticArea = floors.filter((f) => f.utilizedAtticArea != null).reduce((a, f) => a + f.utilizedAtticArea, 0);

  return atticArea - utilizedAtticArea;
};

export const calculateLeftoverAgricultureArea = function (property) {
  const LEFTOVER_AGRICULTURE_USAGE_TYPE = 970;

  return getBuildings(property)
    .filter((b) => {
      return b.usageCode == LEFTOVER_AGRICULTURE_USAGE_TYPE && b.builtArea != null;
    })
    .reduce((a, b) => a + b.builtArea, 0);
};

export const calculateBuildingArea = function (property) {
  return getBuildings(property).reduce((sum, building) => sum + building?.buildingArea || 0, 0);
};

export const calculateRegisteredArea = function (property) {
  return getPlots(property).reduce((sum, plot) => sum + plot?.registeredArea || 0, 0);
};

function getBuildings(property) {
  if (property.buildingOnForeignPlot != null) {
    return property.buildingOnForeignPlot.buildings || [];
  }

  if (property.plots != null) {
    return property.plots.flatMap((p) => p.buildings || []);
  }

  return [];
}

function getFloors(property) {
  const buildings = getBuildings(property);

  return buildings.flatMap((b) => b.floors || []).filter((b) => b != null);
}
