import { render, staticRenderFns } from "./SearchResultCardProperty.vue?vue&type=template&id=dbdbfbd2"
import script from "./SearchResultCardProperty.vue?vue&type=script&lang=js"
export * from "./SearchResultCardProperty.vue?vue&type=script&lang=js"
import style0 from "./SearchResultCardProperty.vue?vue&type=style&index=0&id=dbdbfbd2&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,PropertyLink: require('/app/components/EntityLink/PropertyLink.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,ChromeTooltip: require('/app/components/Chrome/ChromeTooltip.vue').default,KeyValuePairContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePairContainerRow.vue').default,OwnerChip: require('/app/components/EntityLink/OwnerChip.vue').default,ChipContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/ChipContainerRow.vue').default,PropertyCard: require('/app/components/EntityCard/PropertyCard.vue').default})
