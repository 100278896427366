
import SearchResultCardProperty from "~/components/explore/SearchResultCardProperty.vue";
import SearchResultTableCellProperty from "~/components/explore/SearchResultTableCellProperty.vue";
import FindReferenceProperties from "~/graphql/ReferenceProperties/FindReferenceProperties.gql";
import ReferenceProperty from "~/graphql/ReferenceProperties/ReferenceProperty.gql";
import ReferencePropertiesForList from "~/graphql/ReferenceProperties/ReferencePropertiesForList.gql";
import AddToListDialog from "~/components/list/AddToListDialog.vue";
import { calculateWeightedArea, areaWeights } from "~/helpers/area-calculation-helpers.js";
import { getPropertyAddress, getPropertyCentroid } from "~/helpers/property-helpers.js";
import { trackPersonLookup } from "~/helpers/tracking-helpers/person-tracking-helpers.js";
import { copySearchResultsToClipboard } from "~/helpers/clipboard-helpers.js";

export default {
  layout: "default",

  title() {
    return `Reference Properties`;
  },

  components: {
    SearchResultCardProperty,
    SearchResultTableCellProperty,
    AddToListDialog,
  },

  data() {
    return {
      resultsDisplayMode: "cards",

      resultsPerPage: 25,
      resultsCurrentPage: 0,

      searchRadius: 1000,
      searchDebounce: null,
      searchRadiusDebounced: 1000,
      searchOrder: "takeover_date_desc",

      pageChangeDebounce: null,
      pageChangeDebounceTime: 600,

      searchOrderBy: "takeover_date",
      searchOrderDirection: "DESC",

      showAddToList: false,
      propertiesToSave: [],

      filters: [
        this.$t("REF_PROPERTY_FILTER_RESIDENTIAL"),
        this.$t("REF_PROPERTY_FILTER_CONDO_RESIDENTIAL"),
        this.$t("REF_PROPERTY_FILTER_CONDO_IN_FAMILY_HOUSE"),
        this.$t("REF_PROPERTY_FILTER_CONDO_IN_TERRASED_HOUSE"),
        this.$t("REF_PROPERTY_FILTER_FREE_MARKET_TRANSACTION"),
      ],

      keyFigureLabels: [
        {
          datasetKey: "distance",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_DISTNCE"),
        },
        {
          datasetKey: "constructionYears",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_CONSTRUCTION_YEAR"),
        },
        {
          datasetKey: "extensionYears",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_EXTENSION_YEAR"),
        },
        {
          datasetKey: "latestPurchaseDate",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_DATE"),
        },
        {
          datasetKey: "latestPurchaseSum",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_SUM"),
        },
        {
          datasetKey: "weightedAreas",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_WEIGHTED_AREA"),
        },
        {
          datasetKey: "squareMeterPrice",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_SQUAREMETER_PRICE"),
        },
        {
          datasetKey: "landValuation",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LAND_VALUATION"),
        },
        {
          datasetKey: "propertyValuation",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_PROPERTY_VALUATION"),
        },
      ],
    };
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.property.loading || this.$apollo.queries.referenceResults.loading;
    },

    loadingPage: function () {
      return this.$apollo.queries.property.loading;
    },

    loadingResults: function () {
      return this.$apollo.queries.referenceResults.loading;
    },

    referencePropertyBfeNumber: function () {
      return this.$route.query.id && !isNaN(this.$route.query.id) ? parseInt(this.$route.query.id, 10) : undefined;
    },

    referencePropertyAddress: function () {
      return this.getAddress(this.property);
    },

    referenceFilter: function () {
      return {
        lat: this.searchLat,
        lng: this.searchLng,
        radius: parseInt(this.searchRadiusDebounced, 10),
        offset: this.resultsPerPage * this.resultsCurrentPage,
        limit: this.resultsPerPage,
        orderBy: this.searchOrderBy,
        orderDirection: this.searchOrderDirection,
      };
    },

    searchArea: function () {
      return {
        lat: this.searchLat,
        lng: this.searchLng,
        radius: this.searchRadiusDebounced,
      };
    },

    searchLat: function () {
      return getPropertyCentroid(this.property).lat;
    },

    searchLng: function () {
      return getPropertyCentroid(this.property).lng;
    },

    totalResults: function () {
      return this.referenceResults?.pageInfo.totalCount;
    },

    referenceProperties: function () {
      return this.referenceResults
        ? this.referenceResults.hits
            ?.filter((h) => h.property != null)
            .map((h) => {
              let p = h.property;
              return {
                bfeNumber: h.bfeNumber,
                isDividedInCondos: p.isDividedInCondos,
                isPropertyOnList: p.isPropertyOnList,
                condo: p.condo,
                plots: p.plots,
                address: this.getAddress(p) ? this.$options.filters.addressLong(this.getAddress(p)) : this.$t("NO_ACCESS_ADDRESS"),
                owners: p.owners || [],
                administrators: p.administrators || [],
                keyFigures: {
                  bfeNumber: p.bfeNumber,
                  latestPurchaseSum: this.$options.filters.currency(this.getLatestPurchase(p).price),
                  latestPurchaseDate: this.$options.filters.shortdate(this.getLatestPurchase(p).date),
                  squareMeterPrice: this.getSquareMeterPrice(p) ? this.$options.filters.currency(this.getSquareMeterPrice(p)) : undefined,
                  landValuation: this.getLatestValuation(p) ? this.$options.filters.currency(this.getLatestValuation(p).landValuation) : undefined,
                  propertyValuation: this.getLatestValuation(p) ? this.$options.filters.currency(this.getLatestValuation(p).propertyValuation) : undefined,
                  constructionYears: this.getConstructionYears(p).join(", "),
                  extensionYears: this.getExtenstionYears(p).join(", "),
                  distance: this.$options.filters.distance(this.calculateDistanceFromProperty(p)),
                  weightedAreas: this.$options.filters.kvm(this.getWeightedAreas(p)),
                },
              };
            })
        : [];
    },

    propertyCenter: function () {
      return getPropertyCentroid(this.property);
    },

    saveSearchDisabled: function () {
      return this.totalResults > 1000;
    },
  },

  watch: {
    searchRadius(val) {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        this.searchRadiusDebounced = val;
        this.resultsCurrentPage = 0;
      }, 600);
    },

    searchOrder(val) {
      switch (val) {
        case "takeover_date_asc":
          this.searchOrderBy = "takeover_date";
          this.searchOrderDirection = "ASC";
          break;
        case "takeover_date_desc":
          this.searchOrderBy = "takeover_date";
          this.searchOrderDirection = "DESC";
          break;
      }
    },
  },

  mounted() {
    this.$store.commit("reference/setProperties", []);
  },

  apollo: {
    property: {
      query: ReferenceProperty,

      variables: function () {
        return {
          bfeNumber: parseInt(this.$route.params.id, 10),
        };
      },

      update(data) {
        return data.propertyByBFENumber;
      },

      skip() {
        return !this.$route.params.id || isNaN(this.$route.params.id);
      },
    },

    referenceResults: {
      query: FindReferenceProperties,

      variables: function () {
        return { referenceFilter: this.referenceFilter };
      },

      watchLoading(isLoading) {
        this.$store.commit("reference/setLoading", isLoading);
      },

      update(data) {
        this.$store.commit("reference/setSearchArea", this.searchArea);
        this.$store.commit("reference/setProperties", data.findReferenceProperties);
        return data.findReferenceProperties;
      },

      skip: function () {
        return this.property == null;
      },
    },
  },

  methods: {
    scrollToTop() {
      const element = document.querySelectorAll(".container-content-start–bottom–overflow-container")[0];

      if (element) {
        element.scrollTop = 0;
      }
    },

    pageNext() {
      this.resultsCurrentPage += 1;

      this.scrollToTop();
    },

    pagePrevious() {
      this.resultsCurrentPage -= 1;

      this.scrollToTop();
    },

    pageChange(n) {
      clearTimeout(this.pageChangeDebounce);

      this.pageChangeDebounce = setTimeout(() => {
        this.resultsCurrentPage = n - 1;

        this.scrollToTop();
      }, this.pageChangeDebounceTime);
    },

    propertyClicked(property) {
      this.$amplitude.track({
        event_type: "Property lookup",
        event_properties: {
          bfe: property.bfeNumber,
          track: "reference properties list",
        },
      });

      this.$router.push({
        name: "explore-property-id",
        params: { id: property.bfeNumber },
      });
    },

    ownerClicked(owner) {
      switch (owner.type) {
        case "COMPANY":
          this.$amplitude.track({
            event_type: "Company lookup",
            event_properties: {
              cvr: owner.cvr,
              track: "reference properties list",
            },
          });

          this.$router.push({
            name: "explore-company-id-overview",
            params: { id: owner.cvr },
          });
          break;
        case "PERSON":
          if (owner.id) {
            trackPersonLookup(owner.id, owner.name, "reference properties");
            this.$router.push({
              name: "explore-person-id-ownership",
              params: {
                id: owner.id,
              },
            });
          }
          break;

        default:
          break;
      }
    },

    getAddress(property) {
      return getPropertyAddress(property);
    },

    getPropertyType: function (property) {
      if (property.condo != null) {
        return this.$t("OVERVIEW_TYPE_CONDO");
      } else if (property.isDividedInCondos && property.plots != null) {
        return this.$t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS");
      } else if (property.plots != null && !property.isDividedInCondos) {
        return this.$t("OVERVIEW_TYPE_MAIN_PROPERTY");
      } else {
        return this.$t("VALUE_NOT_REGISTERED");
      }
    },

    getLatestPurchase(property) {
      if (property.ownerChanges == null || property.ownerChanges.length == 0)
        return {
          date: null,
          price: null,
        };

      let ownerChangesWithPriceSorted = [...property.ownerChanges]
        .filter((oc) => oc.transactionInfo != null && oc.transactionInfo.totalPurchaseSum != null)
        .sort((a, b) => new Date(b.handoverDate).getTime() - new Date(a.handoverDate).getTime());

      if (ownerChangesWithPriceSorted.length == 0)
        return {
          date: null,
          price: null,
        };

      let latestOwnerChangeWithPrice = ownerChangesWithPriceSorted[0];

      return {
        date: latestOwnerChangeWithPrice.handoverDate,
        price: latestOwnerChangeWithPrice.transactionInfo.totalPurchaseSum,
      };
    },

    getLatestValuation(property) {
      return [...property.valuations].sort((a, b) => new Date(b.valuationDate).getTime() - new Date(a.valuationDate).getTime()).shift();
    },

    getSquareMeterPrice(property) {
      const weightedArea = this.getWeightedAreas(property);

      if (weightedArea && this.getLatestPurchase(property)) {
        return this.getLatestPurchase(property).price / weightedArea;
      } else {
        return undefined;
      }
    },

    getConstructionYears(property) {
      let buildings = [];

      if (property.plots) {
        buildings.push(...property.plots.flatMap((p) => p.buildings).filter((b) => b));
      } else if (property.condo?.unit?.building) {
        buildings.push(property.condo.unit.building);
      }

      let constructionYears = buildings.flatMap((b) => b.constructionYear).filter((cy) => cy && cy !== 1000); // Year 1000 is sometimes used in BBR when not registered

      return [...new Set(constructionYears)].sort((a, b) => b - a);
    },

    getExtenstionYears(property) {
      let buildings = [];

      if (property.plots) {
        buildings.push(...property.plots.flatMap((p) => p.buildings).filter((b) => b));
      } else if (property.condo?.building) {
        buildings.push(property.condo.building);
      }

      let extenstionYears = buildings.flatMap((b) => b.extensionYear).filter((ey) => ey && ey !== 1000); // Year 1000 is sometimes used in BBR when not registered

      return [...new Set(extenstionYears)].sort((a, b) => b - a);
    },

    getWeightedAreas(property) {
      return calculateWeightedArea(property, areaWeights);
    },

    calculateDistanceFromProperty(property) {
      let propertyCenter = this.propertyCenter;
      let referencePropertyCenter = getPropertyCentroid(property);

      if (propertyCenter && referencePropertyCenter) {
        return this.distance(propertyCenter.lat, propertyCenter.lng, referencePropertyCenter.lat, referencePropertyCenter.lng);
      } else {
        return undefined;
      }
    },

    // https://stackoverflow.com/a/21623206
    distance(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295; // Math.PI / 180
      var c = Math.cos;
      var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

      return 12742 * Math.asin(Math.sqrt(a)) * 1000; // 2 * R; R = 6371 km
    },

    saveToList(bfeNumber) {
      this.propertiesToSave = [bfeNumber];
      this.showAddToList = true;
    },

    saveAllToList() {
      this.propertiesToSave = this.referenceProperties;
      this.showAddToList = true;
    },

    async saveSearchToList() {
      const filter = { ...this.referenceFilter };

      filter.offset = 0;
      filter.limit = this.totalResults;

      const result = await this.$apollo.query({
        query: ReferencePropertiesForList,
        variables: { referenceFilter: filter },
      });

      const bfes = result.data.findReferenceProperties.hits;

      this.propertiesToSave = bfes;
      this.showAddToList = true;
    },

    copyToClipboard(labels, data, address) {
      copySearchResultsToClipboard(labels, data, address);
    },
  },
};
