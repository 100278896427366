import "core-js/modules/es6.array.filter.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    "class": _vm.$style.flexContainer
  }, [!_vm.$isMobile ? _c('portal', {
    attrs: {
      "to": "map-controls"
    }
  }, [_c('div', {
    "class": _vm.$style.rangeSlider
  }, [_c('Composition', {
    scopedSlots: _vm._u([{
      key: "backgrounds",
      fn: function fn() {
        return [_c('CompositionBackgroundCard', {
          attrs: {
            "color": "--color-white",
            "shadow": "large"
          }
        })];
      },
      proxy: true
    }, {
      key: "layouts",
      fn: function fn() {
        return [_c('CompositionLayoutSingle', {
          scopedSlots: _vm._u([{
            key: "container",
            fn: function fn() {
              return [_c('Copy', {
                attrs: {
                  "type": "label",
                  "size": "small",
                  "color": "--color-black-900"
                }
              }, [_vm._v("\n                " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_RANGE_SLIDER")) + "\n              ")]), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "small"
                }
              }), _vm._v(" "), _c('Slider', {
                attrs: {
                  "valueMin": 100,
                  "valueMax": 20000,
                  "valueStep": 100,
                  "valueLabelTextAddition": _vm.$t('REFERENCE_PROPERTIES_LABEL_RANGE_SLIDER_UNIT'),
                  "colorBackground": "--color-black-50",
                  "colorForeground": "--color-black-900"
                },
                scopedSlots: _vm._u([{
                  key: "iconBefore",
                  fn: function fn() {
                    return [_c('Icon', {
                      attrs: {
                        "id": "adjust",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "iconAfter",
                  fn: function fn() {
                    return [_c('Icon', {
                      attrs: {
                        "id": "adjust",
                        "size": "large",
                        "color": "--color-black-900"
                      }
                    })];
                  },
                  proxy: true
                }], null, false, 1363835126),
                model: {
                  value: _vm.searchRadius,
                  callback: function callback($$v) {
                    _vm.searchRadius = $$v;
                  },
                  expression: "searchRadius"
                }
              })];
            },
            proxy: true
          }], null, false, 2152183129)
        })];
      },
      proxy: true
    }], null, false, 4239974330)
  })], 1)]) : _vm._e(), _vm._v(" "), _c('Composition', {
    attrs: {
      "explodeHeight": _vm.resultsDisplayMode === 'table',
      "loading": _vm.loadingPage,
      "loadingEnabled": "",
      "loadingColor": "--color-black-50",
      "loadingTransformOrigin": "top center"
    },
    scopedSlots: _vm._u([{
      key: "layouts",
      fn: function fn() {
        return [_c('CompositionLayoutPagedResults', {
          attrs: {
            "loading": _vm.loadingResults,
            "loadingColor": "--color-black-50",
            "resultsTotal": _vm.totalResults || 0,
            "resultsPerPage": _vm.resultsPerPage,
            "resultsCurrentPage": _vm.resultsCurrentPage,
            "labelPagePrevious": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_PREVIOUS'),
            "labelPageNext": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_NEXT'),
            "labelPagePosition": _vm.$t('REFERENCE_PROPERTIES_LABEL_PAGE_POSITION')
          },
          on: {
            "page-next": function pageNext($event) {
              return _vm.pageNext();
            },
            "page-previous": function pagePrevious($event) {
              return _vm.pagePrevious();
            },
            "page-change": _vm.pageChange
          },
          scopedSlots: _vm._u([!_vm.loadingPage ? {
            key: "header",
            fn: function fn() {
              return [_c('div', {
                "class": _vm.$style.header
              }, [_c('Copy', {
                attrs: {
                  "type": "header",
                  "size": "large",
                  "color": "--color-black-900"
                }
              }, [_vm._v("\n              " + _vm._s(_vm.totalResults ? _vm.totalResults : 0) + "\n              " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_REFERENCES_FOR")) + "\n            ")]), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "horizontal",
                  "size": "small"
                }
              }), _vm._v(" "), _c('PropertyLink', {
                attrs: {
                  "address": _vm.referencePropertyAddress,
                  "bfeNumber": _vm.referencePropertyBfeNumber
                }
              }), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "horizontal",
                  "size": "large"
                }
              }), _vm._v(" "), _c('KeyValuePair', {
                attrs: {
                  "direction": "horizontal",
                  "spacingSize": "small"
                },
                scopedSlots: _vm._u([{
                  key: "containerStart",
                  fn: function fn() {
                    return [_c('PushButton', {
                      attrs: {
                        "size": "small",
                        "color": "--color-transparent",
                        "underline": "",
                        "omitPadding": "",
                        "disabled": _vm.saveSearchDisabled
                      },
                      on: {
                        "click": _vm.saveSearchToList
                      },
                      scopedSlots: _vm._u([{
                        key: "iconBefore",
                        fn: function fn() {
                          return [_c('Icon', {
                            attrs: {
                              "id": "bookmark",
                              "size": "large",
                              "color": "--color-purple-900"
                            }
                          })];
                        },
                        proxy: true
                      }, {
                        key: "copy",
                        fn: function fn() {
                          return [_c('Copy', {
                            attrs: {
                              "type": "body",
                              "size": "small",
                              "color": "--color-purple-900"
                            }
                          }, [_c('strong', [_vm._v(_vm._s(_vm.$t("LIST_SAVE_ALL", {
                            count: _vm.totalResults
                          })))])])];
                        },
                        proxy: true
                      }], null, false, 3119587188)
                    })];
                  },
                  proxy: true
                }, {
                  key: "containerEnd",
                  fn: function fn() {
                    return [_vm.saveSearchDisabled ? _c('KeyValuePair', {
                      attrs: {
                        "direction": "horizontal"
                      },
                      scopedSlots: _vm._u([{
                        key: "containerStart",
                        fn: function fn() {
                          return [_c('Icon', {
                            attrs: {
                              "id": "priority_high",
                              "size": "medium",
                              "color": "--color-pink-900"
                            }
                          })];
                        },
                        proxy: true
                      }, {
                        key: "containerEnd",
                        fn: function fn() {
                          return [_c('Copy', {
                            attrs: {
                              "type": "body",
                              "size": "small",
                              "color": "--color-pink-900",
                              "wrap": ""
                            }
                          }, [_vm._v("\n                      " + _vm._s(_vm.$t("REF_PROP_TOO_MANY_RESULTS_ERROR")) + "\n                    ")])];
                        },
                        proxy: true
                      }], null, false, 3692446894)
                    }) : _vm._e()];
                  },
                  proxy: true
                }], null, false, 165899120)
              })], 1), _vm._v(" "), _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "small"
                }
              }), _vm._v(" "), _c('Hint', {
                attrs: {
                  "headerText": _vm.$t('REFERENCE_PROPERTIES_LABEL_HINT_HEADER'),
                  "bodyText": _vm.$t('REFERENCE_PROPERTIES_LABEL_HINT_BODY', {
                    distance: _vm.searchRadius
                  }),
                  "bylineText": _vm.$t('REFERENCE_PROPERTIES_LABEL_HINT_BYLINE')
                }
              })];
            },
            proxy: true
          } : null, {
            key: "chips",
            fn: function fn() {
              return [_c('ChipContainerRow', {
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_ACTIVE_FILTERS")) + "\n              ")])];
                  },
                  proxy: true
                }])
              }, [_vm._v(" "), _vm._l(_vm.filters, function (filter, index) {
                return _c('Chip', {
                  key: "filter".concat(index),
                  attrs: {
                    "colorForeground": "--color-purple-900",
                    "colorBackground": "--color-purple-100",
                    "outline": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "copy",
                    fn: function fn() {
                      return [_c('Copy', {
                        attrs: {
                          "type": "label",
                          "size": "small",
                          "color": "--color-purple-900",
                          "title": _vm.$t('REFERENCE_PROPERTIES_LABEL_ACTIVE_FILTERS_TITLE')
                        }
                      }, [_vm._v("\n                  " + _vm._s(filter) + "\n                ")])];
                    },
                    proxy: true
                  }], null, true)
                });
              })], 2)];
            },
            proxy: true
          }, {
            key: "controls",
            fn: function fn() {
              return [_vm.$isMobile ? _c('div', {
                "class": _vm.$style.rangeSliderMobile
              }, [_c('Composition', {
                attrs: {
                  "omitPadding": ""
                },
                scopedSlots: _vm._u([{
                  key: "backgrounds",
                  fn: function fn() {
                    return [_c('CompositionBackgroundCard', {
                      attrs: {
                        "color": "--color-black-50"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "layouts",
                  fn: function fn() {
                    return [_c('CompositionLayoutSingle', {
                      scopedSlots: _vm._u([{
                        key: "container",
                        fn: function fn() {
                          return [_c('Copy', {
                            attrs: {
                              "type": "label",
                              "size": "small",
                              "color": "--color-black-900"
                            }
                          }, [_vm._v("\n                      " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_RANGE_SLIDER")) + "\n                    ")]), _vm._v(" "), _c('CompositionSpacer', {
                            attrs: {
                              "direction": "vertical",
                              "size": "small"
                            }
                          }), _vm._v(" "), _c('Slider', {
                            attrs: {
                              "valueMin": 100,
                              "valueMax": 10000,
                              "valueStep": 100,
                              "valueLabelTextAddition": _vm.$t('REFERENCE_PROPERTIES_LABEL_RANGE_SLIDER_UNIT'),
                              "colorBackground": "--color-black-100",
                              "colorForeground": "--color-black-900"
                            },
                            scopedSlots: _vm._u([{
                              key: "iconBefore",
                              fn: function fn() {
                                return [_c('Icon', {
                                  attrs: {
                                    "id": "adjust",
                                    "size": "small",
                                    "color": "--color-black-900"
                                  }
                                })];
                              },
                              proxy: true
                            }, {
                              key: "iconAfter",
                              fn: function fn() {
                                return [_c('Icon', {
                                  attrs: {
                                    "id": "adjust",
                                    "size": "large",
                                    "color": "--color-black-900"
                                  }
                                })];
                              },
                              proxy: true
                            }], null, false, 1363835126),
                            model: {
                              value: _vm.searchRadius,
                              callback: function callback($$v) {
                                _vm.searchRadius = $$v;
                              },
                              expression: "searchRadius"
                            }
                          })];
                        },
                        proxy: true
                      }], null, false, 18603342)
                    })];
                  },
                  proxy: true
                }], null, false, 2470520357)
              })], 1) : _vm._e(), _vm._v(" "), _vm.$isMobile ? _c('CompositionSpacer', {
                attrs: {
                  "direction": "vertical",
                  "size": "small"
                }
              }) : _vm._e(), _vm._v(" "), _c('div', {
                "class": _vm.$style.controls
              }, [_c('DropDown', {
                attrs: {
                  "label": _vm.searchOrder === 'takeover_date_desc' ? _vm.$t('REFERENCE_PROPERTIES_SORT_ORDER_HANDOVERDATE_DESC') : _vm.$t('REFERENCE_PROPERTIES_SORT_ORDER_HANDOVERDATE_ACS'),
                  "colorBackground": "--color-black-50",
                  "colorForeground": "--color-black-900",
                  "minWidth": _vm.$isMobile ? '100%' : '16rem',
                  "maxWidth": _vm.$isMobile ? '100%' : '22rem'
                },
                model: {
                  value: _vm.searchOrder,
                  callback: function callback($$v) {
                    _vm.searchOrder = $$v;
                  },
                  expression: "searchOrder"
                }
              }, [_c('DropDownItem', {
                attrs: {
                  "label": _vm.$t('REFERENCE_PROPERTIES_SORT_ORDER_HANDOVERDATE_DESC'),
                  "value": "takeover_date_desc"
                }
              }), _vm._v(" "), _c('DropDownItem', {
                attrs: {
                  "label": _vm.$t('REFERENCE_PROPERTIES_SORT_ORDER_HANDOVERDATE_ACS'),
                  "value": "takeover_date_asc"
                }
              })], 1), _vm._v(" "), !_vm.$isMobile ? _c('ToggleButton', {
                attrs: {
                  "colorBackground": "--color-black-50",
                  "colorBackgroundActive": "--color-black-500",
                  "colorBackgroundHover": "--color-black-100",
                  "colorLabelActive": "--color-white",
                  "colorLabelInactive": "--color-black-900"
                },
                model: {
                  value: _vm.resultsDisplayMode,
                  callback: function callback($$v) {
                    _vm.resultsDisplayMode = $$v;
                  },
                  expression: "resultsDisplayMode"
                }
              }, [_c('ToggleButtonItem', {
                attrs: {
                  "value": "cards",
                  "labelText": _vm.$t('REFERENCE_PROPERTIES_LABEL_VIEW_CARDS'),
                  "iconId": "view_stream"
                }
              }), _vm._v(" "), _c('ToggleButtonItem', {
                attrs: {
                  "value": "table",
                  "labelText": _vm.$t('REFERENCE_PROPERTIES_LABEL_VIEW_TABLE'),
                  "iconId": "list"
                }
              })], 1) : _vm._e()], 1)];
            },
            proxy: true
          }, _vm.referenceProperties != null && _vm.referenceProperties.length > 0 ? {
            key: "results",
            fn: function fn() {
              return [_vm._l(_vm.referenceProperties, function (result, index) {
                return _vm.resultsDisplayMode === 'cards' ? _c('div', {
                  key: "result-cards-".concat(index),
                  "class": _vm.$style.flexContainer
                }, [_c('SearchResultCardProperty', {
                  attrs: {
                    "bfeNumber": result.bfeNumber,
                    "addressText": result.address,
                    "typeText": _vm.getPropertyType(result),
                    "keyFigures": result.keyFigures,
                    "keyFigureLabels": _vm.keyFigureLabels,
                    "owners": result.owners,
                    "administrators": result.administrators,
                    "isSavedOnList": result.isPropertyOnList
                  },
                  on: {
                    "save-to-list": _vm.saveToList,
                    "copy-to-clipboard": _vm.copyToClipboard
                  }
                }), _vm._v(" "), index < _vm.referenceProperties.length - 1 ? _c('CompositionSpacer', {
                  attrs: {
                    "direction": "vertical",
                    "size": "small"
                  }
                }) : _vm._e()], 1) : _vm._e();
              }), _vm._v(" "), _vm.resultsDisplayMode === 'table' ? _c('div', {
                "class": _vm.$style.flexContainer
              }, [_c('Composition', {
                attrs: {
                  "explodeHeight": _vm.resultsDisplayMode === 'table'
                },
                scopedSlots: _vm._u([{
                  key: "backgrounds",
                  fn: function fn() {
                    return [_c('CompositionBackgroundCard', {
                      attrs: {
                        "color": "--color-white"
                      }
                    })];
                  },
                  proxy: true
                }, {
                  key: "layouts",
                  fn: function fn() {
                    return [_c('SimpleTable', [_c('SimpleTableRow', {
                      attrs: {
                        "fixed": "",
                        "hoverEffectDisable": ""
                      }
                    }, [_c('SimpleTableCell', {
                      attrs: {
                        "fixed": _vm.$isMobile ? false : true
                      }
                    }, [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_ADDRESS")) + "\n                      ")])], 1), _vm._v(" "), _c('SimpleTableCell', [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_TYPE")) + "\n                      ")])], 1), _vm._v(" "), _vm._l(_vm.keyFigureLabels, function (label, index) {
                      return _c('SimpleTableCell', {
                        key: index
                      }, [_c('Copy', {
                        attrs: {
                          "type": "label",
                          "size": "small",
                          "color": "--color-black-900"
                        }
                      }, [_vm._v("\n                        " + _vm._s(label.translation) + "\n                      ")])], 1);
                    }), _vm._v(" "), _c('SimpleTableCell', [_c('Copy', {
                      attrs: {
                        "type": "label",
                        "size": "small",
                        "color": "--color-black-900"
                      }
                    }, [_vm._v("\n                        " + _vm._s(_vm.$t("REFERENCE_PROPERTIES_LABEL_TABLE_OWNERS_AND_ADMINISTRATORS")) + "\n                      ")])], 1)], 2), _vm._v(" "), _vm._l(_vm.referenceProperties, function (result, index) {
                      return _c('SearchResultTableCellProperty', {
                        key: "result-table-".concat(index),
                        attrs: {
                          "bfeNumber": result.bfeNumber,
                          "addressText": result.address,
                          "typeText": _vm.getPropertyType(result),
                          "keyFigures": result.keyFigures,
                          "keyFigureLabels": _vm.keyFigureLabels,
                          "owners": result.owners.filter(function (o) {
                            return o;
                          }),
                          "isPropertyOnList": result.isPropertyOnList,
                          "administrators": result.administrators
                        },
                        on: {
                          "save-to-list": _vm.saveToList,
                          "copy-to-clipboard": _vm.copyToClipboard
                        }
                      });
                    })], 2)];
                  },
                  proxy: true
                }], null, false, 270906266)
              })], 1) : _vm._e()];
            },
            proxy: true
          } : {
            key: "results",
            fn: function fn() {
              return [[_c('EmptyState', {
                attrs: {
                  "color": "--color-black-high-emphasis",
                  "textHeader": _vm.$t('EMPTY_STATE_EXPLORE_RESULTS_TITLE'),
                  "textBody": _vm.$t('EMPTY_STATE_REFERENCE_RESULTS_SUBTITLE')
                }
              })]];
            },
            proxy: true
          }], null, true)
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('portal', {
    attrs: {
      "to": "chrome-notification-layer-1"
    }
  }, [_c('AddToListDialog', {
    attrs: {
      "visible": _vm.showAddToList,
      "properties": _vm.propertiesToSave,
      "origin": "company portfolio"
    },
    on: {
      "close": function close($event) {
        _vm.showAddToList = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };