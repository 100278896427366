
import PropertyCard from "~/components/EntityCard/PropertyCard.vue";
import PropertyLink from "~/components/EntityLink/PropertyLink.vue";

export default {
  name: "SearchResultCardProperty",

  components: {
    PropertyCard,
    PropertyLink,
  },

  inheritAttrs: false,

  props: {
    /**
     * BFE number
     */
    bfeNumber: {
      type: [String, Number],
      default: "",
    },

    /**
     * text of address field
     */
    addressText: {
      type: String,
      default: "",
    },

    /**
     * text of type field
     */
    typeText: {
      type: String,
      default: "",
    },

    /**
     * interface: [{key, value}]
     */
    keyFigures: {
      type: Object,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{key, value}]
     */
    keyFigureLabels: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{name:String, type:"company"|"person"}]
     */
    owners: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * interface: [{name}]
     */
    administrators: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * is item saved on a list
     */
    isSavedOnList: {
      type: Boolean,
      default: false,
    },

    /**
     * Lists that the property exists on
     */
    lists: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * Remarks
     */
    remarks: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      hover: false,
      characterLimit: 17,
    };
  },

  methods: {
    getPropertyTypeIcon(type) {
      if (type) {
        switch (type) {
          case this.$t("OVERVIEW_TYPE_CONDO"): {
            return "icon-condo-tt";
          }
          case this.$t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS"): {
            return "icon-condo-tt";
          }
          case this.$t("OVERVIEW_TYPE_MAIN_PROPERTY"): {
            return "icon-property-tt";
          }
          case this.$t("VALUE_NOT_REGISTERED"): {
            return "";
          }
        }
      }

      return "";
    },

    getKeyFigure(entity, key) {
      return entity[key] || this.$t("EXPLORE_UNKNOWN");
    },

    showKeyFigure(entity, key) {
      return entity && entity[key] && entity[key].length > this.characterLimit;
    },
  },
};
