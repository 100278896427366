// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flexContainer_YB7to{display:flex;flex-direction:column;height:100%}.rangeSlider_zKioL{margin:1rem auto 0;width:100%}.rangeSliderMobile_7a-Q7{display:flex}.controls_k3GxJ{justify-content:space-between}.controls_k3GxJ,.header_\\+AHG-{display:flex;width:100%}.header_\\+AHG-{align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexContainer": "flexContainer_YB7to",
	"rangeSlider": "rangeSlider_zKioL",
	"rangeSliderMobile": "rangeSliderMobile_7a-Q7",
	"controls": "controls_k3GxJ",
	"header": "header_+AHG-"
};
module.exports = ___CSS_LOADER_EXPORT___;
